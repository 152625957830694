
/********** Base **********/

	$base_color: #505050;
	$body_background: #F7F7FA;
	$font_pack: 'Roboto', Helvetica,sans-serif;
	$font_size: 14px; //0.9rem
	$app_color: $base_color;
	$border_color: #DFE0E1;
	$placeholder-color: rgba(#000000,0.4);
	$placeholder-color-white: #FFFFFF;
	$input_background: #FFFFFF;
	$input_background_readonly: #EEF0F1; //#EEF0F1
	$input_border: #ABABAB;
	$input_border_focus: #505050;
	$input_border_expected: #FFCD40;
	$input_required: rgba(#F8E71C,0.3);


/********** Colours **********/

	$primary_color: #468EE5;

	$black: #000000;
	$white: #FFFFFF;

	$grey_1: #4A4A4A;
	$grey_2: #585D69;
	$grey_3: #5D6978;


	$lighter_grey: #F5F6F7;
	$light_grey: #ECEEF3;
	$grey: #CCCCCC;
	$mid_grey: #7E8C99;
	$dark_grey: #525B6B;
	$darker_grey: #465765;
	$toolbar_grey: #61707A;
	$toolbar_grey_light: #EDF1F4;

	$disabled_grey: #ABABAB;
	$blue: #468EE5;
	$navy: #2B495E;

	$red: #D75363;
	$green: #72BF44;
	$orange: #F07D23;
	$yellow: #FFCD40;

	$ticker_up: #2BE77A;
	$ticker_down: #EF6627;

	$state_hover: rgba($blue,0.1);

	$shadow_1: rgba($grey_1,0.2);
	$shadow_2: rgba($black,0.2);

	$hover: rgba($blue,0.1);

	$notification: #F5A623;

	$group_color_1: #A7C5E5;
	$group_color_2: #D39FE9;
	$group_color_3: #E9E19F;
	$group_color_4: #9A75FF;

	$tree_btn_hover: #F4F6F7;

	$alert_red: #D75363;

	$state_confirmed: #29BBA9 ;
	$state_pending: #616F7C ;
	$state_issued: #71ADD1 ;


/********** Bank Colours **********/
	$purple: #414583;
	$purple_blush: #C9D0E2;
	$light_purple: #283284;
	$dark_purple: #283260;
	$extra_light_purple:#F3F3F5;
	$gradient_purple_1: #806ff7;
	$gradient_purple_2:#8594ff;
	$background_purple:#8492FE;
	$purple_gradient: linear-gradient(90deg, $gradient_purple_1 0%, $gradient_purple_2 100%);
	$overlay: rgba($background_purple,0.9);
  $gray:#00000008;

/********** Pages **********/


/********** Modal **********/
	$modal_background: #FFFFFF;



/********** Button **********/
	$btn_primary_bg: #72BF44;
	$btn_primary_bg_hover: darken($btn_primary_bg, 10%);
	$btn_primary_color: #FFFFFF;
	$btn_primary_color_hover: #FFFFFF;


	$btn_secondary_bg: #51738B;
	$btn_secondary_bg_hover:darken($btn_secondary_bg, 10%);
	$btn_secondary_color: #FFFFFF;
	$btn_secondary_color_hover: #FFFFFF;


/******* Icons ********/



	$x: -50%;
	$y: -50%;


/******* Icons ********/


/******* Media Queries ********/

	$mobile: "(min-width: 0px) and (max-width: 767px)";
	$mobile_portrait: "(min-width: 0px) and (max-width: 767px) and (orientation: portrait)";
	$mobile_landscape: "(min-width: 320px) and (max-width: 767px) and (orientation: landscape)";

	$tablet: "(min-width: 768px) and (max-width: 1024px)";
	$tablet_portrait: "(min-width: 768px) and (max-width: 1023px) and (orientation: portrait)";
	$tablet_landscape: "(min-width: 1024px) and (max-width: 1279px)";

	$desktop: "(min-width: 1025px) and (max-width: 1439px)";
	$desktop_wide: "(min-width: 1440px)";
	$desktop_extra_wide: "(min-width: 1680px)";
	$min_desktop_wide: "(min-width: 1280px)";

	$max_height: "(max-height: 760px)";



