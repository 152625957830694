/* You can add global styles to this file, and also import other style files */
@import "./assets/css/base.scss";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/prebuilt-themes/pink-bluegrey.css';
@import '~@angular/material/prebuilt-themes/purple-green.css';

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  left: 45%;
  margin: -25px 0 0 -25px;
  width: 200px;
  height: 300px;

  & .path {
    stroke:#90beef
    ;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/source-sans-pro.css') format('truetype');
}
